import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { catchError, map, pluck, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { UserProfileByIdQuery } from '@app/pages/settings/users/qraphql/users.queries';

@Injectable()
export class UserNameResolver {
	constructor(private apollo: Apollo) {}

	resolve(snapshot: ActivatedRouteSnapshot) {
		return this.apollo
			.query<any>({
				query: UserProfileByIdQuery,
				variables: {
					userPsId: snapshot.params.id,
				},
			})
			.pipe(
				take(1),
				pluck('data', 'profiles'),
				map(profile => {
					if (!profile) {
						return '';
					}
					return `${profile.firstName} ${profile.lastName}`;
				}),
				catchError(err => {
					console.error(err.networkError);
					return EMPTY;
				})
			);
	}
}
