import gql from 'graphql-tag';

export const OrganizationQuery = gql`
	query OrganizationQuery($organizationPsId: UUID) {
		organizations(organizationPsId: $organizationPsId) {
			id
			logoUrl
			name
			psId
			smallLogoUrl
			permissions
			teams {
				name
				psId
				teamType
			}
			type
		}
	}
`;

export const OrganizationNameQuery = gql`
	query OrganizationQuery($organizationPsId: UUID) {
		organizations(organizationPsId: $organizationPsId) {
			name
		}
	}
`;

export interface IOrganizationsForSelect {
	id: string;
	name: string;
	psId: string;
	teams: {
		name: string;
		psId: string;
	}[];
	type: string;
}
