import gql from 'graphql-tag';

export const UsersInOrganizations = gql`
	query UsersInOrganizations {
		organizations {
			type
			id
			psId
			permissionLevel
			logoUrl
			teams {
				teamType
				psId
				permissionLevel
				id
				teamType
				users {
					email
					firstName
					lastName
					phoneNumber
					countryCode
					avatarUrl
					smallAvatarUrl
					psId
					userRole
				}
				name
				smallLogoUrl
			}
			name
		}
	}
`;

export const SingleTeamMemberName = gql`
	query SingleTeamMemberName($userPsId: UUID!) {
		profiles(userPsId: $userPsId) {
			firstName
			lastName
		}
	}
`;

export const SingleTeamMember = gql`
	query SingleTeamMember($userPsId: UUID!) {
		profiles(userPsId: $userPsId) {
			avatarUrl
			countryCode
			phoneNumber
			email
			firstName
			lastName
			id
			psId
			organization {
				psId
				userOrganizationRole
			}
			team {
				psId
				teamType
			}
		}
	}
`;

export const UserProfileByIdQuery = gql`
	query UserProfileByIdQuery($userPsId: UUID) {
		profiles(userPsId: $userPsId) {
			firstName
			lastName
		}
	}
`;
