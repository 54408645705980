import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { catchError, map, pluck, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { OrganizationNameQuery } from '@app/pages/settings/users/qraphql/organizations.queries';

@Injectable()
export class OrganizationNameResolver {
	constructor(private apollo: Apollo) {}

	resolve(snapshot: ActivatedRouteSnapshot) {
		return this.apollo
			.query<any>({
				query: OrganizationNameQuery,
				variables: {
					organizationPsId: snapshot.params.organizationId,
				},
			})
			.pipe(
				take(1),
				pluck('data', 'organizations'),
				map(([organization]) => {
					if (!organization) {
						return '';
					}
					return organization.name;
				}),
				catchError(err => {
					console.error(err.networkError);
					return EMPTY;
				})
			);
	}
}
