import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	IUserFormData,
	IUserOrTeamMemberData,
} from '@app/pages/settings/users/interfaces/users-management.interfaces';
import { IEditUserData } from '@app/pages/settings/users/pages/edit-user/edit-user.interfaces';
import { SingleTeamMember } from '@app/pages/settings/users/qraphql/users.queries';
import { map, pluck } from 'rxjs/operators';
import { AuUtilsFunctions, AVAILABLE_USER_ROLES } from '@app/shared';
import { Apollo } from 'apollo-angular';
import { TeamMemberEditMutation } from '@app/pages/settings/users/qraphql/user.mutations';

@Injectable()
export class AuEditUserService {
	constructor(private apollo: Apollo) {}

	getUserData(routerParams: { id: string }): Observable<IUserOrTeamMemberData> {
		if (!routerParams.id) {
			throw new Error(`Can't resolve user id in router parameters`);
		} else {
			return this.apollo
				.query<{ profiles: IEditUserData }>({
					query: SingleTeamMember,
					variables: {
						userPsId: routerParams.id,
					},
					fetchPolicy: 'network-only',
				})
				.pipe(
					pluck('data', 'profiles'),
					map(profiles => this.transformData(profiles))
				);
		}
	}

	private transformData(_data: IEditUserData) {
		const data = AuUtilsFunctions.deepCopyObject(_data);

		data.teamPsId = data?.team[0].psId;
		data.teamType = data?.team[0].teamType;
		data.organizationPsId = data?.organization.psId;
		data.isSuperAdmin =
			data?.organization.userOrganizationRole === AVAILABLE_USER_ROLES.SUPER_ADMIN;

		return data;
	}

	engageUserMutation(userPsId: string, formUserData: IUserFormData) {
		const variables: IEditUserData = {
			psId: userPsId,
			countryCode: formUserData.countryCode,
			phoneNumber: formUserData.phoneNumber,
			firstName: formUserData.firstName,
			lastName: formUserData.lastName,
			email: formUserData.email,
			isSuperAdmin: formUserData.isSuperAdmin,
		};

		if (formUserData.file) {
			variables.file = formUserData.file;
		}

		return this.apollo.mutate<{ success: boolean }>({
			mutation: TeamMemberEditMutation,
			variables,
			context: {
				useMultipart: true,
			},
		});
	}
}
