import gql from 'graphql-tag';
import { IPermission } from '@app/pages/settings/users/pages/add-edit-team/add-edit-team.component';

export const TeamQuery = gql`
	query TeamQuery($teamPsId: UUID!) {
		team(teamPsId: $teamPsId) {
			id
			psId
			permissionLevel
			permissions
			name
			smallLogoUrl
			logoUrl
			teamType
			users {
				email
				firstName
				lastName
				smallAvatarUrl
				countryCode
				phoneNumber
				psId
				userRole
			}
		}
	}
`;

export const TeamNameQuery = gql`
	query TeamQuery($teamPsId: UUID!) {
		team(teamPsId: $teamPsId) {
			name
		}
	}
`;

export const TeamForEditingQuery = gql`
	query TeamForEditingQuery($teamPsId: UUID!) {
		team(teamPsId: $teamPsId) {
			teamType
			permissions
			name
			smallLogoUrl
			logoUrl
			psId
		}
	}
`;

export interface ITeamForEditingQuery {
	name: string;
	smallLogoUrl: string;
	logoUrl: string;
	psId: string;
	permissions: IPermission[];
}
